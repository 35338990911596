html, body {
  font-family: 'Beth Ellen', cursive;
  height: 100%;
  margin: 0;
  /* background-color: rgb(205, 209, 218); */
  min-height: 100vh; /* 100% of the viewport height */
}

.App {
  text-align: center;
  padding: 20px 0; 
  min-height: 100vh; /* 100% of the viewport height */
  transition: opacity 0.5s ease-in-out;
  position: relative; /* Add this */
}

h1 {
  font-family: 'Beth Ellen', cursive;
  font-size: 48px;
  color: black;
}
hr {
  width: 50%;
  margin: 20px auto;
}

.hand-drawn-line {
  margin: 20px auto;
  display: block;
}

.sections {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.sections a {
  font-family: 'Beth Ellen', cursive;
  color: black;
  text-decoration: none;
  padding: 5px 10px;
  transition: background-color 0.3s;
}

.container {
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '100vh';
  padding: '0 20px';
}

.content {
  font-size: '20px';
  text-align: 'center';
  max-width: '800px';
}